import React, { useState, useCallback } from "react";
import { Sheet, ModalClose, Modal } from "@mui/joy";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    LinearProgress,
    Typography,
    IconButton,
    Box,
    Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import thankyou from "../../Assets/img/thankyou.png";
import thankyou1 from "../../Assets/img/thankyou1.png";
import bg from "../../Assets/img/bg.png";


const Thankyou = ({ open, onClose }) => {
    const handleDeclineCaptcha = () => {
        onClose();
    };

    const handleCaptcha = () => {
        onClose();
    }

    return (
        <Modal
            open={open}
            onClose={handleDeclineCaptcha}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            disableAutoFocus={true}
        >
            <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 928,
                    width: 733,
                    // height: 510,
                    borderRadius: "md",
                    // p: 3,
                    boxShadow: "lg",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#222"
                }}
            >
                <ModalClose
                    variant="outlined"
                    sx={{
                        top: "calc(-1/2 * var(--IconButton-size))",
                        right: "calc(-1/3 * var(--IconButton-size))",
                        boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                        borderRadius: "50%",
                        bgcolor: "background.surface",
                        zIndex: "9",
                        backgroundColor: "#222",
                        border: "2px solid #007dff",
                        color: "#007dff",
                    }}
                />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textcolor="inherit"
                    fontWeight="lg"
                    mb={1}
                    className=""
                >
                </Typography>
                <Grid id="modal-desc1" textcolor="text.tertiary">
                    <Grid container spacing={2} style={{marginLeft: 0, width: "100%", backgroundImage:`url(${bg})`, borderRadius: "10px"}}>
                    {/* <Grid item xs={12} style={{position: "relative"}}> */}
                        <Grid item xs={12} className="modal-bg" style={{
                            // backgroundImage:`url(${thankyou})`, 
                            minHeight: 510, 
                            maxHeight: 600, 
                            // margin: "1.5% 0 1% 0",
                            border: "2px solid #007dff",
                            borderRadius: "10px",
                            margin: "30px",
                            }}>
                            {/* <img src={thankyou} /> */}
                            <div className="modal-body-box">
                            <h2 className="thank-note-head">Your recording has been received.</h2>
                            <p className="thank-note-p">Thank you for adding your</p>
                            <p className="thank-note-p">voice to One World.</p>
                            {/* <p className="thank-note-p" style={{marginTop: 0}}>With love <br />Coldplay</p> */}
                            
                            <img src={thankyou1} className="thank-note-img" />
                            <Button onClick={handleDeclineCaptcha} style={{margin:"0 auto"}} className="redis-btn contained-button2 thank-note-button" color="secondary" variant="contained">
                                Close
                            </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Sheet>
        </Modal>
    );
};

export default Thankyou;