import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

import { CircularProgress } from "@mui/joy";

function CircularWithValueLabel(props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgb(0 0 0 / 84%)" }}
      open={props.open}
    >
      <CircularProgress
        variant="solid"
        size="md"
        determinate
        value={props.value}
        sx={{
          position: "relative",
          display: "inline-flex",
          position: "absolute",
          width: "244px !important",
          height: "244px !important",
          left: "0",
          top: "0",
          margin: "auto",
          right: 0,
          bottom: 0,
        }}
        className={"upload-progress"}
      >
        <span className="percentage-progress">{`${Math.round(
          props.value
        )}`}</span>
        <span className="percentage-percent">%</span>
      </CircularProgress>
    </Backdrop>
  );
}

export default function UploadProgress(props) {
  const [progress, setProgress] = React.useState(10);
  const currentTimer = React.useRef(null);

  React.useEffect(() => {
    currentTimer.current = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 2000);
    return () => {
      clearInterval(currentTimer.current);
    };
  }, []);
  React.useEffect(() => {
    if (props.requestCompleted) {
      setProgress(100);
      props.handleLoaderComplete();
      clearInterval(currentTimer.current);
    }
  }, [props.requestCompleted]);

  return <CircularWithValueLabel open={props.open} value={progress} />;
}
