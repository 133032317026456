import React, { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/joy/Typography";
import smline from "../Assets/img/sm-ling.png";
import uk from "../Assets/img/uk.png";
import CountryNames from "../Assets/CountryNames/CountryNames";
import Grid from "@mui/material/Grid";

import Tooltip from '@mui/material/Tooltip';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import {APIURL} from "../constant";

const LeaderBoard = () => {
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [showLess, setshowLess] = useState(true);
  const leaderboardApiUrl =
  APIURL+"leaderboard.php";

  const [addRecoardSectionOpen, setAddRecoardSectionOpen] =
    React.useState(false);

  useEffect(() => {
    getLeaderboardData();
  }, []);

  const getLeaderboardData = () => {
    const formData = new FormData();
    axios
      .get(leaderboardApiUrl)
      .then(async(response) => {
        // console.log(response.data, "respionselead");
        let rawData = response?.data?.leaderboardData;
        // sorting on frontend side 
        // if(rawData){
        //   let avlLeadrboard = await rawData?.sort(function(a, b) {
        //     var textA = a?.countryCode?.toUpperCase();
        //     var textB = b?.countryCode?.toUpperCase();
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        //   });
        // }
        setLeaderboardData(rawData);
      })
      .catch((error) => {
        console.log(error, "ERROR LEADERBOARD");
      });
  };
  const countryMap = {};
   CountryNames.filter((option) => {
    countryMap[option.cca3] = option.flags;
  });

  // show all fn 
  const showAll = () =>{
    setshowLess(false)
  }

  return (
    <>
      <Grid className="separator"></Grid>
      <Typography variant="body1" color="secondary" className="record-avl">
        LEADERBOARD
      </Typography>
      <Grid className="table-class">
        <Grid className="leaderboard-h">
          <Grid className="flex-box">
            <div className="heading1">
              Rank{" "}
              <span>
                <img src={smline} className="smline" alt="smline" />
              </span>
            </div>
            <div className="heading2 heading3country">Country/Region</div>
            {/* <span>
              <img src={smline} className="smline" alt="smline" />
            </span>
            <div className="heading2">Total Submissions</div> */}
          </Grid>
        </Grid>
        {leaderboardData?.length && showLess &&
          leaderboardData?.map((leaderData, index) => (
            index <= 4 ? (
              <Tooltip key={leaderData?.countryCode} title={leaderData?.countryName} placement="top">
            <Grid className="leaderboard-sb" key={"sub_"+index}>
              <Grid className="flex-box" key={"box_"+index}>
                <div className="heading1" key={"head_"+index}>
                  {leaderData.rank}
                  <span>
                    {<img src={smline} className="smline" alt="smline" />}
                  </span>
                </div>

                <div className="heading2 heading3country align_country">
                  {" "}
                  <img width={34} height={20} src={countryMap[leaderData.countryCode]?.png} /> <span className="country-show">{leaderData.countryCode}</span>
                </div>
                {/* <span>
                  {<img src={smline} className="smline" alt="smline" />}
                </span>
                <div className="heading2">{leaderData.totalRecording}</div> */}
              </Grid>
            </Grid>
            </Tooltip>
            ):(
              index > 4 && index + 1 === leaderboardData?.length &&
              <Tooltip title="Show All" placement="bottom" key={"tool_"+index}>
                  <div className="dot-bdr" onClick={showAll}><KeyboardDoubleArrowDownIcon className="dot-bdr-down" /></div>
              </Tooltip>
            )
          ))
        }
        {/* show full table  */}
        {leaderboardData?.length && !showLess && 
          leaderboardData?.map((leaderData, index) => (
            <Tooltip key={leaderData?.countryCode} title={leaderData?.countryName} placement="top">
            <Grid className="leaderboard-sb" key={"sub_"+index}>
              <Grid className="flex-box" key={"box_"+index}>
                <div className="heading1" key={"head_"+index}>
                  {leaderData.rank}
                  <span>
                    {<img src={smline} className="smline" alt="smline" />}
                  </span>
                </div>
                <div className="heading2 heading3country align_country">
                  {" "}
                  <img width={34} height={20} src={countryMap[leaderData.countryCode]?.png} /> <span className="country-show">{leaderData.countryCode}</span>
                </div>
                {/* <span>
                  {<img src={smline} className="smline" alt="smline" />}
                </span>
                <div className="heading2">{leaderData.totalRecording}</div> */}
              </Grid>
            </Grid>
            </Tooltip>
          ))}
          {leaderboardData?.length && !showLess &&
          <Tooltip title="Show Less" placement="bottom" key={"tool2_"}>
            <div className="dot-bdr" onClick={()=>{setshowLess(true)}}><KeyboardDoubleArrowUpIcon className="dot-bdr-up" /></div>
          </Tooltip>
          }
      </Grid>
    </>
  );
};

export default LeaderBoard;
