import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function WelcomeCard() {
  return (
    <Card sx={{ minWidth: 275, backgroundColor: "#222", boxShadow: "none", paddingTop: 0 }}>
      <CardContent style={{paddingBottom: 0}} className='textcontentcentralized'>
        <Typography className='introduction_top' gutterBottom>
          Hello everybody. We hope you are all ok in these wild times.
        </Typography>
        <br />
        <Typography className='introduction_top' gutterBottom>
          We have nearly finished MOON MUSIC. If you'd like to be on it too, perhaps you could add your voice to a song called One World. (We would love that a lot.)
        </Typography>
        <br />
        <Typography className='introduction_top' gutterBottom>
          All you have to do is record yourself singing "Ahhhhh" for a few seconds.
        </Typography>
        <br />
        <Typography className='introduction_top' gutterBottom>
          You can either copy the note below, or sing a G or C in any octave.
        </Typography>
        <br />
        <Typography className='introduction_top' gutterBottom>
        </Typography>
        <Typography className='introduction_top' gutterBottom>
          Thankyou so much.
        </Typography>
        <br />
        <Typography className='introduction_top' gutterBottom>
          Love
        </Typography>
        <Typography className='introduction_top' gutterBottom>
          Chris, Jonny, Guy & Will 
        </Typography>
      </CardContent>
    </Card>
  );
}