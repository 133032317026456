import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Auth({handleClose, open, handleSubscribe, setUsername, username, setPassword, password, error, setError, logo, disabled}) {
  
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='subscribe_head'><img src={logo} className="App-logo" alt="One World" style={{backgroundColor: "#222", padding: "10px", borderRadius: "5px", maxHeight: 70}} /></DialogTitle>
        <DialogContent>
          <DialogContentText className='subscribe_sub'>
            Sign In to access.
          </DialogContentText>
          <input
            onChange={e => {
                setError(false)
                let value = e.target.value;
                // value = value.replace(/[^A-Za-z0-9]/gi, "");
                setUsername(value)
            }}
            className='subscribe_input'
            label="Username"
            placeholder="Username"
            type="text"
            fullWidth
            value={username}
          />
          <input
            onChange={e => {
                setError(false)
                let value1 = e.target.value;
                // value1 = value1.replace(/[^A-Za-z0-9]/gi, "");
                setPassword(value1)
            }}
            className='subscribe_input'
            label="Password"
            placeholder="Password"
            type="password"
            fullWidth
            value={password}
          />
          {error && <small className='emailerror'>Wrong username / password!</small>}
        </DialogContent>
        <DialogActions style={{paddingBottom: "30px", paddingRight: "25px"}}>
          <Button className='outlined-button' onClick={handleClose}>Go Back</Button>
          <Button className='contained-button btn_classy' onClick={handleSubscribe} disabled={disabled} >Sign In</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}