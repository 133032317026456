import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Sheet, ModalClose, Modal, Typography } from "@mui/joy";
import {APIURL} from "../constant";

const CaptchaDialogBox = ({ open, onClose, submitAudioFile }) => {
  const [token, setToken] = useState(null);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const apiCaptchaUrl =
  APIURL+"captchaverification.php";
  const captchaRef = useRef(null);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (token) {
      console.log(`hCaptcha Token: ${token}`);
      handleCaptchaVerification(token);
    }
  }, [token]);

  const handleCaptchaVerification = (captchaToken) => {
    const formData = new FormData();
    formData.append("recaptcha_token", captchaToken);
    // Make the API call using Axios
    axios
      .post(apiCaptchaUrl, formData)
      .then((response) => {
        if (response.data.status === "success") {
          submitAudioFile();
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textcolor="inherit"
          fontWeight="lg"
          mb={1}
        ></Typography>
        <div id="modal-desc" textcolor="text.tertiary">
          {load && (
            <form
              onSubmit={() => {
                captchaRef.current.execute();
              }}
            >
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="dark"
                ref={captchaRef}
                sitekey={"6LfhGlYoAAAAAECE8X1RFbD00AzOxEf2hxYsNpWy"}
                onChange={setToken}
                type="image"
                onErrored={(error) => console.log(error, "error occurred")}
                onExpired={(error) => console.log(error, "token expired")}
                // asyncScriptOnLoad={asyncScriptOnLoad}
              />
              {error ? "Please Verify this CAPTCHA Again" : null}
            </form>
          )}
        </div>
      </Sheet>
    </Modal>
  );
};

export default CaptchaDialogBox;
