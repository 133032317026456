import React, { useEffect } from "react";
import logo from "./logo.png";
import "./App.css";
import Cookies from 'js-cookie';
// import { v4 as uuidv4 } from 'uuid';
import { AudioRecorder, VideoPlayer } from "./Components"
import Auth from "./Components/modals/Auth";

const App = () => {

  const [open, setOpen] = React.useState(false); //true to open auth modal
  const [disabled, setdisabled] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const handleClose = () => {
    window.location.href = "https://www.coldplay.com/";
  };

  const handleSubscribe = () => {
    if(username==="oneworld" && password==="coldplayfan"){
      setOpen(false);
    }else{
      setError(true)
    }
  }

  useEffect(() => {
    if(username && password){
      setdisabled(false)
    }else{
      setdisabled(true)
    }
  }, [username, password]);

  function handleClick() {
    window.location.reload();
  }
  // const randomId = uuidv4(); // Generate a random UUID
  // useEffect(() => {
    // Cookies.set('sessionID', randomId, { expires: 7 });  // Set a cookie with a random ID that expires in 7 days
  // }, []);

  return (
    <div className="App main-container">
      {open ? (
        <Auth 
          handleClose={handleClose} 
          open={open} 
          handleSubscribe={handleSubscribe}
          setUsername={setUsername}
          usernam={username}
          setPassword={setPassword}
          password={password}
          setError={setError}
          error={error}
          logo={logo}
          disabled={disabled}
        />
      ) : (
        <>
          <div className="video-container">
            <header className="App-header">
              {<img src={logo} className="App-logo" alt="One World" onClick={handleClick} style={{ cursor: "pointer" }} />}
              {/* <h1 className="one-world">ONE WORLD</h1> */}
              {/* <p className="sub-heading"> ONE WORLD <i className="italic">A</i> MILLION VOICES</p> */}
              {/* <p className="sub-heading-one">A MILLION VOICES PROJECT</p> */}
            </header>
            {/* <VideoPlayer /> */}
            {/* <Title />
        <Player />
        <Insructions /> */}
          </div>
          <div className="bottom-section">
            <AudioRecorder />
          </div>
        </>
      )}
    </div>
  )
}

export default App;
