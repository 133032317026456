import { useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import Regions from "wavesurfer.js/dist/plugins/regions.js";
const useWavesurfer = (containerRef, options,activeRegion) => {
  const wavesurfer = useRef();
  const wavesurferRef = useRef(null);
  const regionsPluginRef = useRef(null);
  // Initialize wavesurfer when the container mounts
  // or any of the props change
  const {url,isRecording} = options;
  useEffect(() => {
    if (!containerRef.current) return;
    const regionsPlugin = Regions.create({
      regions: [],
      dragSelection: false,
      slop: 10,
    });
    const registerPlugins = options?.type !== "files" ? [regionsPlugin] : [];
    const ws = WaveSurfer.create({
      ...options,
      plugins: registerPlugins,
      container: containerRef.current,
    });
    wavesurfer.current = ws;
    wavesurferRef.current = ws;
    regionsPluginRef.current = regionsPlugin;
    ws.on("ready", () => {
      console.log("Ready");
    });
    ws.on("decode", () => {
      console.log("decode",ws.getDuration() - ws.getDuration() / 60);
      options.setCurrentTime(`${Math.round(ws.getDuration())}:0000`);
      // if (options?.type !== "files" && !options?.isRecording) {
      //   regionsPlugin.clearRegions();
      //   regionsPlugin.addRegion({
      //     start: 0,
      //     end: Math.round(ws.getDuration() - ws.getDuration() / 60),
      //     color: "hsla(200, 50%, 70%, 0.3)",
      //     minLength: 1,
      //     maxLength: 10,
      //     resize: true,
      //   });
      // }
    });

    regionsPlugin.on("region-in", (region) => {
      activeRegion.current = region;
    });
    regionsPlugin.on("region-out", (region) => {
      if (activeRegion.current === region) {
        wavesurfer.current.pause();
      }
    });
    regionsPlugin.on('region-updated', (region) => {
      console.log('Updated region', region)
      activeRegion.current = region;
      handleTrimAudio(region);
    })
    regionsPlugin.on("region-clicked", (region, e) => {
      e.stopPropagation(); // prevent triggering a click on the waveform
      handleTrimAudio(region);
    });
    return () => {
      ws.destroy();
    };
  }, [url,containerRef,isRecording]);

  const handleTrimAudio = async (selectedRegion) => {
    const wavesurfer = wavesurferRef.current;
    const regionsPlugin = regionsPluginRef.current;

    if (wavesurfer && regionsPlugin) {
      // const selectedRegion = regionsPlugin.list[Object.keys(regionsPlugin.list)[0]];
      if (selectedRegion) {
        const start = selectedRegion.start;
        const end = selectedRegion.end;

        // Create a new AudioContext to manipulate the audio
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();

        // Convert Blob to ArrayBuffer

        const blob = options.blob;

        const arrayBuffer = await blob.arrayBuffer();

        // Decode the audio data
        const decodedBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Trim the audio buffer
        const duration = end - start;
        const numberOfChannels = decodedBuffer.numberOfChannels;
        const sampleRate = decodedBuffer.sampleRate;

        const trimmedBuffer = audioContext.createBuffer(
          numberOfChannels,
          duration * sampleRate,
          sampleRate
        );

        for (let channel = 0; channel < numberOfChannels; channel++) {
          const sourceData = decodedBuffer.getChannelData(channel);
          const targetData = trimmedBuffer.getChannelData(channel);

          for (let i = 0; i < trimmedBuffer.length; i++) {
            const sourceIndex = Math.floor(start * sampleRate + i);
            targetData[i] = sourceData[sourceIndex];
          }
        }

        // Create a new AudioBufferSourceNode for trimmed audio
        const sourceNode = audioContext.createBufferSource();
        sourceNode.buffer = trimmedBuffer;
        sourceNode.connect(audioContext.destination);
        // Convert the trimmed audio buffer to a Blob
        const audioBlob = bufferToBlob(trimmedBuffer);
        // Convert the audio Blob to a data URL
        // const trimmedAudioUrl = URL.createObjectURL(audioBlob);
        options.setTrimValue(audioBlob);
      }
    }
  };

  // Function to convert an AudioBuffer to a Blob
  const bufferToBlob = (buffer) => {
    const numberOfChannels = buffer.numberOfChannels;
    const interleaved = new Float32Array(buffer.length * numberOfChannels);
    const channelData = [];

    for (let channel = 0; channel < numberOfChannels; channel++) {
      channelData.push(buffer.getChannelData(channel));
    }

    for (let sample = 0; sample < buffer.length; sample++) {
      for (let channel = 0; channel < numberOfChannels; channel++) {
        interleaved[sample * numberOfChannels + channel] =
          channelData[channel][sample];
      }
    }

    const audioBlob = new Blob([interleaved], { type: "audio/wav" });
    return audioBlob;
  };

  return wavesurfer;
};
export default useWavesurfer;
