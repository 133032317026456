import React, { useState, useCallback, useEffect } from "react";
import { Sheet, ModalClose, Modal } from "@mui/joy";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import upload from "../Assets/img/upload-icon.png";

const useStyles = makeStyles((theme) => ({
  fileName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
  },
}));
const FileUploadDialog = ({ open, onClose, onFilesUploaded }) => {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();
  const maxSize = 10 * 1024 * 1024; // 10 MB

  useEffect(() => {
    if (selectedFiles.length) {
      onFilesUploaded(selectedFiles);
      onClose();
      setError(false);
    }
  }, [selectedFiles]);

  const handleFileDrop = (acceptedFiles,rejectedFiles) => {
    if(rejectedFiles.length){
      setError(true);
    }else{
      setError(false);
    }
    let totalFiles=acceptedFiles.length
    const validFiles = [];
    acceptedFiles.forEach((file,index) => {
      if (file.type.startsWith("audio/") && file.size <= maxSize) {
        const audio = new Audio();
        audio.addEventListener("loadedmetadata", () => {
          if (audio.duration <= 10) {
            // Process the valid file here
            validFiles.push(file);
            if(index+1 === totalFiles){
              setSelectedFiles(validFiles);
            }
          } else {
            setError(true);
            console.log("Audio file duration exceeds 10 seconds:", file);
          }
        });
        audio.src = URL.createObjectURL(file);
      } else {
        console.log("Invalid audio file:", file);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileDrop,
    maxSize,
    accept: {
      "audio/mp3": [".mp3"],
      "audio/x-m4a": [".m4a"],
    },
    maxFiles: 1, // Set the maximum number of files
  });

  const handleClose = () => {
    onClose();
    setUploading(false);
    setError(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: 733,
          // height: 510,
          borderRadius: "md",
          // p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        {/* <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textcolor="inherit"
          fontWeight="lg"
          mb={1}
        ></Typography> */}
        <Grid id="modal-desc" textcolor="text.tertiary">
          <div className="uploadFiles">
            {uploading ? (
              <div>
                {files.map((file, index) => (
                  <Grid
                    key={index + file.name}
                    container
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <Typography className={classes.fileName}>
                        {file.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <LinearProgress
                        size="md"
                        variant="determinate"
                        value={file.progress}
                        color={
                          file.error
                            ? "error"
                            : file.progress === 100
                            ? "success"
                            : "primary"
                        }
                      />
                    </Grid>
                    <Grid key={index} item xs={2}>
                      {file.progress === 100 ? (
                        file.error ? (
                          <IconButton color="error">
                            <CancelOutlinedIcon />
                          </IconButton>
                        ) : (
                          <IconButton color="success">
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        )
                      ) : (
                        <Typography color="black">{file.progress}%</Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </div>
            ) : (
              <div className="uploadText" {...getRootProps()}>
                <input {...getInputProps()} type="file" className="fileupload" />
                <img src={upload} />
                <h2 className="drag-head">Drop your voice recording here</h2>
                <Typography className="drag-sub-head">
                  Supports only <span className="drag-bold-head">.M4a</span> &{" "}
                  <span className="drag-bold-head">.MP3</span> formats with file size under 
                  <span className="drag-bold-head"> 500kb</span>
                </Typography>
              </div>
            )}
          </div>
          {error && <small className="emailerror" style={{fontSize: "15px"}}>Please upload a single file within a maximum duration of 10 seconds.</small>}
          <div className="choosefIles">
            <div className="upload-btn-wrapper" {...getRootProps()}>
              <button className="btn" style={{backgroundColor: "var(--cp-yellow, #EBE900)", border: "none"}}>Browse files to upload</button>
              <input type="file" name="myfile" {...getInputProps()} />
            </div>
          </div>
        </Grid>
      </Sheet>
    </Modal>
  );
};

export default FileUploadDialog;
