import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { Sheet, ModalClose, Modal } from "@mui/joy";
import { Button, Typography } from "@mui/material";
import {APIURL, PRIVACYPOLICY, TANDC} from "../constant";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from "@mui/joy";

const AcceptNewsLetter = ({ open, onClose, handleStartRecording,setisSessionID }) => {
  const apiUrl =
  APIURL+"termcondition.php";
  
  const [termCondition, settermCondition] = React.useState(false);
  const [newsLetter, setnewsLetter] = React.useState(false);
  const [albumBooklet, setalbumBooklet] = React.useState(false);
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastName] = React.useState("");
  const [disabled, setdisabled] = React.useState(true);

  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Voice already exists. Please try another email.");

  const uncheckedIcon = <svg className="check_uncheck_position" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect x="2" y="2" width="20" height="20" stroke="#0061AA" strokeWidth="4"/>
                        </svg>;
  const checkedIcon = <svg className="check_uncheck_position" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="2" y="2" width="20" height="20" stroke="#0061AA" strokeWidth="4"/>
                        <path d="M7 13.5L9.9999 16L16.5 8" stroke="#0061AA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>;

  const handleChangeTnC = (event) => {
    setfirstName("")
    setlastName("")
    setUserEmail("")
    setIsValidEmail(false)
    settermCondition(event.target.checked);
  };
  const handleChangeNews = (event) => {
    setnewsLetter(event.target.checked);
  };
  const handleChangeBooklet = (event) => {
    setalbumBooklet(event.target.checked);
  };
  React.useEffect(()=>{
    setUserEmailError(false)
    if(!firstName.trim() || !lastName.trim() || !isValidEmail){
        setdisabled(true)
    }else{
        setdisabled(false)
    }
    // if(albumBooklet && !firstName.trim()){
    //     setdisabled(true)
    // }
  }, [termCondition, albumBooklet, firstName, lastName, userEmail])

  const handleDecline = (selectedFlag) => {
    // handleApiCall(selectedFlag);
    setfirstName("")
    setlastName("")
    setUserEmail("")
    setIsValidEmail(false)
    setUserEmailError(false)
    settermCondition(false)
    setalbumBooklet(false)
    setnewsLetter(false)
    onClose();
  };

  const handleApiCall = (sessionID) => {
    if(termCondition && userEmail){
    const formData = new FormData();
    formData.append("termCondition", termCondition ? 1 : 0);
    // formData.append("sessionID", Cookies.get("sessionID"));
    formData.append("sessionID", sessionID);
    formData.append("newsLetter", newsLetter ? 1 : 0);
    formData.append("albumBooklet", albumBooklet ? 1 : 0);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailID", userEmail);
    // Make the API call using Axios
    axios
      .post(apiUrl, formData)
      .then((response) => {
        if (response?.data?.error!=true){ 
            handleStartRecording()
            setisSessionID(sessionID)
            onClose();
        }else{
          setErrorMessage(response?.data?.message)
          setUserEmailError(true)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
    }
  };
  const handleAcceptRecord = (selectedFlag) => {
    // if (!Cookies.get("sessionID")) {
    //   const randomId = uuidv4();
    //   Cookies.set("sessionID", randomId, { expires: 14 }); // Set a cookie with a random ID that expires in 7 days
    // }
    handleApiCall(uuidv4());
    // onClose();
  };

  const handleEmailValidation = (emailVal) => {
    setUserEmailError(false)
    const newEmail = emailVal;
    setUserEmail(emailVal);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailPattern.test(newEmail));
  };

  return (
    <Modal
      open={open}
      onClose={() => handleDecline(0)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      disableAutoFocus={true}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: 733,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
        className="accept_modal"
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textcolor="inherit"
          fontWeight="lg"
          mb={1}
          className="privacy-title-accept"
        >
          Select your preferences
        </Typography>
        <div id="modal-desc" textcolor="text.tertiary">
          <div className="text-layout-privacy1">
            <FormControlLabel
             className="news_checkbox"
             control={
              <Checkbox
                icon={uncheckedIcon}
                checkedIcon={checkedIcon}
             />
             }
             label={<span>Accept the One World project’s <a href={TANDC} target="_blank">Terms & Conditions</a></span>}
             checked={termCondition}
             onChange={handleChangeTnC}
            />
            <Grid container className="album_booklet" spacing={3} style={{padding: "0px 40px", display: !termCondition && "none"}}>
                <Grid
                    lg={5}
                    md={5}
                    sm={5}
                    xs={12}
                    className={"firstNameGrid"}
                >
                    <input
                        onChange={e => {
                          let value = e.target.value;
                          // value = value.replace(/[^A-Za-z.-]*$/gi, "");
                          setfirstName(value)
                        }}
                        value={firstName}
                        title="First Name"
                        className="input-width rad"
                        placeholder="First Name"
                        type="text"
                        required
                    />
                </Grid>
                <Grid
                    lg={7}
                    md={7}
                    sm={7}
                    xs={12}
                    className={"secondNameGrid"}
                >
                    <input
                        onChange={e => {
                          let value = e.target.value;
                          // value = value.replace(/[^A-Za-z.-]*$/gi, "");
                          setlastName(value)
                        }}
                        value={lastName}
                        title="Last Name"
                        className="input-width rad"
                        placeholder="Last Name"
                        type="text"
                        required
                    />
                </Grid>
                <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={"emailGrid"}
                >
                    <input
                      onChange={(e) => handleEmailValidation(e.target.value)}
                      value={userEmail}
                      title="Email address"
                      className="input-width rad"
                      placeholder="Email address"
                      type="email"
                      required
                    />
                    
                    {!isValidEmail && userEmail != "" && (
                      <small className="emailerror">Please enter a valid email</small>
                    )}
                    {userEmailError && (
                      <small className="emailerror">{errorMessage}</small>
                    )}
                </Grid>
                {/* <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={"album_booklet_terms"}
                >
                    <small className="terms_text_grey">By submitting my information, I agree to receive personalized updates and marketing messages about Coldplay, based on my information, interests, activities, website visits and device data and in accordance with the <a href={"https://www.wminewmedia.com/privacy/"} target="_blank">Privacy Policy</a>. I understand that I can opt-out at any time by emailing <a href="mailto:privacypolicy@wmg.com"> privacypolicy@wmg.com.</a></small>
                </Grid> */}
            </Grid>
            <FormControlLabel
             className="news_checkbox"
             control={
              <Checkbox
                icon={uncheckedIcon}
                checkedIcon={checkedIcon}
             />
             }
             label="Sign up for Coldplay’s newsletter and updates about the One World project" 
             checked={newsLetter}
             onChange={handleChangeNews}
            />
            <Grid container className="album_booklet" spacing={3} style={{padding: "0px 40px"}}>
                <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={"album_booklet_terms"}
                >
                    <small className="terms_text_grey">By submitting my information, I agree to receive personalized updates and marketing messages about Coldplay, based on my information, interests, activities, website visits and device data and in accordance with the <a href={"https://www.wminewmedia.com/privacy/"} target="_blank">Privacy Policy</a>. I understand that I can opt-out at any time by emailing <a href="mailto:privacypolicy@wmg.com"> privacypolicy@wmg.com.</a></small>
                </Grid>
            </Grid>
            <FormControlLabel
             className="news_checkbox" 
             control={
              <Checkbox
                icon={uncheckedIcon}
                checkedIcon={checkedIcon}
             />
             }
             label="Credit my full name - as entered above - in the digital booklet accompanying the track"
             checked={albumBooklet}
             onChange={handleChangeBooklet} 
            />
          </div>
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            <Button
              onClick={() => handleAcceptRecord(1)}
              className="redis-btn contained-button btn_classy"
              color="secondary"
              variant="contained"
              disabled={disabled}
            >
              Record Now
            </Button>
            <Button
              onClick={() => handleDecline(0)}
              color="primary"
              className="outlined-button"
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
};

export default AcceptNewsLetter;
