import React, { useState, useEffect, useRef } from "react";
// import Cookies from "js-cookie";
import axios from "axios";
import {
  CircularProgress,
  IconButton,
  Grid,
  Typography,
  Button,
} from "@mui/joy";
import MicRecorder from "mic-recorder-to-mp3";
import WaveSurferPlayer from "./WaveSurferPlayer";
import FileUploadDialog from "./FileUploadDialog";
// import AcceptTermsDialogBox from "./AcceptTermsDialogBox";
import AcceptNewsLetter from "./AcceptNewsLetter";
import CaptchaDialogBox from "./CaptchaDialogBox";
import Thankyou from "./modals/Thankyou";
import LeaderBoard from "./LeaderBoard";
import Selectdropdown from "./Selectdropdown";
import Instructions from "./Instructions";
import UploadProgress from "./modals/UploadProgress";
import ButtonLink from "./modals/ButtonLink";
import WelcomeCard from "./modals/WelcomeCard";
// import AddIcon from "@mui/icons-material/Add";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import ReplayIcon from "@mui/icons-material/Replay";
// import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
// import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import Tooltip from "@mui/material/Tooltip";
import {APIURL} from "../constant";
import AudioRecorderPolyfill from 'audio-recorder-polyfill'
if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorderPolyfill
}

const AudioRecorder = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isSessionID, setisSessionID] = useState(false);
  const [isalreadySubmit, setisalreadySubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [resetRecording, setResetRecording] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [audioUrl, setAudioUrl] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState(false);
  const [selectedcountryCode, setSelectedcountryCode] = useState("GBR");
  const [selectedcountryName, setSelectedcountryName] = useState("");
  const apiUrl = APIURL+"create.php";
  const apiCaptchaUrl = APIURL+"captchaverification.php";
  const [acceptTC, setAcceptTC] = useState(false);
  const [captchaVerify, setCaptchaVerify] = useState(false);
  const [acceptThankyou, setAcceptThankyou] = useState(false);
  const [blob, setBlob] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Voice already exists. Please try another email.");

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [mediaRecorderOut, setmediaRecorderOut] = useState(null);
  const trimValue = useRef(null);
  const recorder = new MicRecorder({
    bitRate: 128,
  });
  const updateInterval = useRef(null);
  const audioChunks = useRef([]);
  useEffect(() => {
    if (selectedType) handleAcceptTerms(selectedType);
  }, [selectedType]);

  const handleAcceptTerms = (type) => {
    // if (!Cookies.get("sessionID")) {
    if(!isSessionID){
      setAcceptTC(true);
    } else {
      if (type === "Add") {
        handleOpenDialog();
      } else {
        handleStartRecording();
        setSelectedType(null);
      }
    }
  };

  const handleDeclineTerms = () => {
    setAcceptTC(false);
    setSelectedType(null);
  };

  const handleCaptchaDialogOpen = () => {
    setCaptchaVerify(true);
    // console.log("chunks1 - - ", chunks)
    // console.log("trimValue2 - - ", trimValue)
  };

  const handleCaptchaDialogClose = () => {
    setCaptchaVerify(false);
  };

  const handleDeclineThankyou = () => {
    setAcceptThankyou(false);
    window.location.reload();
  };

  const handleStartRecording = () => {
    if (selectedType === "Add") {
      handleOpenDialog();
    } else {
      // setTimer(3);
      setShowTimer(true);
      setAudioUrl(null);
      setProgress(0);
      // Start the 3-second countdown timer
      let timerCount = 3;
      const timerInterval = setInterval(() => {
        if (timerCount > 0) {
          setTimer(timerCount);
          timerCount--;
        } else {
          clearInterval(timerInterval);
          setAudioUrl(null);
          setShowSpinner(true);
          startRecording();
        }
      }, 1000);
    }
  };

  const startRecording = () => {
    // recorder
    //   .start()
    //   .then(() => {
    //     let timerCount = 1;
    //     updateInterval.current = setInterval(() => {
    //       recorder
    //         .getMp3()
    //         .then(([buffer, blob]) => {
              
              
    //           audioChunks.current.push(blob);
    //           const audioBlob = new Blob(audioChunks.current, {
    //             type: "audio/mpeg",
    //           });
    //           setBlob(audioBlob);
    //           setAudioUrl(URL.createObjectURL(audioBlob));
    //           // if (audioChunks.current.length === 1) {
    //           //   handleStopRecording();
    //           // }
              
    //         })
    //         .catch((e) => console.log(e));
    //     }, 10000); // Update every 1 second
    //     let progressInterval = setInterval(() => {
    //       setShowTimer(false);
    //       setTimer(0);
    //       setIsRecording(true);
    //       setProgress((prevProgress) =>
    //         prevProgress >= 100 ? 0 : prevProgress + 10
    //       );
    //       if (timerCount>=10) {
    //         clearInterval(progressInterval);
    //         handleStopRecording();
    //       }
    //       timerCount++;
    //     }, 1000);
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
    const constraints = {
      audio: {
        // sampleRate: 48000,
        // channelCount: 1,
        // volume: 1.0,
        // echoCancellation: true,
        noiseSuppression: false,
      },
      video: false
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = (event) => {
          setShowTimer(false);
          setTimer(0);
          setIsRecording(true);
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 0 : prevProgress + 10
          );
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
            const audioBlob = new Blob(audioChunks.current, {
              type: "audio/mpeg",
            });
            setBlob(audioBlob);
            setAudioUrl(URL.createObjectURL(audioBlob));
          }
          if(audioChunks?.current?.length === 10){
            mediaRecorder.stop();
          }
        };

        mediaRecorder.onstop = () => {
          handleStopRecording();
        };

        mediaRecorder.start(1000);
        setTimeout(()=>{
          mediaRecorder.stop();
        }, 9600)

        setmediaRecorderOut(mediaRecorder)
      })
      .catch((error) => {
        console.error('Error accessing the microphone:', error);
      });
  };

  const handleStopRecordingOut = () => {
    if(audioChunks?.current?.length >=4){
      mediaRecorderOut?.stop();
    }
  }
    const handleStopRecording = () => {
    setChunks(audioChunks.current);
    audioChunks.current = [];
    setShowSpinner(false); // Hide the circle spinner progress
    setIsRecording(false);
    setResetRecording(true);
    setShowTimer(false);
    // recorder.stop();
    // clearInterval(updateInterval.current);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedType(null);
    setOpenDialog(false);
  };

  const handleEmailValidation = (emailVal) => {
    setUserEmailError(false)
    const newEmail = emailVal;
    setUserEmail(emailVal);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailPattern.test(newEmail));
  };

  const handleFilesUploaded = (files) => {
    // Do something with the uploaded files
    setSelectedFiles(files);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {
    const paginatedFiles = selectedFiles.slice(
      currentPage * 1,
      (currentPage + 1) * 1
    );
    setCurrentFiles(paginatedFiles);
  }, [currentPage, selectedFiles]);

  const checkMarkerAdded = (newCutedBlob) => {
    setAudioUrl(newCutedBlob);
  };
  useEffect(()=>{
    // console.log("chunks - - ", chunks)
    // console.log("trimValue - - ", trimValue)
  }, [trimValue, chunks])
  const submitAudioFile = () => {
    // console.log("chunks1 - - ", chunks)
    // console.log("trimValue2 - - ", trimValue)
    handleCaptchaDialogClose();
    setLoader(true);

    // Create a FormData object
    const formData = new FormData();
    if (selectedFiles.length) {
      selectedFiles.forEach((innerFile) => {
        // console.log("file upload - - - - ", innerFile)
        formData.append("audioFiles", innerFile);
      });
    } else {
      // if (trimValue.current) {
      //   const file2 = new File(
      //     [trimValue.current],
      //     `recording-trim-${new Date()}.mp3`,
      //     {
      //       type: "audio/mpeg",
      //     }
      //   );
      //   // console.log("file - - - - ", file)
      //   formData.append("audioFiles", file2);
      // } else 
      if (audioUrl && chunks && chunks.length) {
        const audioBlob = new Blob(chunks, { type: "audio/mp3" });
        const file = new File([audioBlob], `recording-${new Date()}.mp3`, {
          type: "audio/mpeg",
        });
        // console.log("file2 - - - - ", file)
        formData.append("audioFiles", file);
      } else {
        setLoader(false);
        console.error("Audio URL not available.");
        return;
      }
    }
    // formData.append("emailID", userEmail);
    formData.append("countryCode", selectedcountryCode);
    formData.append("countryName", selectedcountryName);
    formData.append("timestamp", new Date().toISOString());
    // formData.append("sessionID", Cookies.get("sessionID"));
    formData.append("sessionID", isSessionID);

    // Make the API call using Axios
    axios
      .post(apiUrl, formData)
      .then((response) => {
        if(response?.data?.error!=true){
        setAudioUrl(null);
        // setUserEmail("");
        setSelectedcountryCode(null);
        setSelectedcountryName(null);
        setRequestCompleted(true);
        if(response?.data?.userData?.newsLetter=="1"){
          console.log('window._satellite.track("email sign-up")')
          window._satellite.track("email sign-up")
        }else{
          console.log('window._satellite.track("non-signup submission")')
          window._satellite.track("non-signup submission")
        }
        // Handle the response data here
        }else{
          setErrorMessage(response?.data?.message)
          setUserEmailError(true)
          setLoader(false);
          setRequestCompleted(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
        setRequestCompleted(true);

        // Handle errors here
      });
  };
  const handleLoaderComplete = () => {
    setTimeout(() => {
      setLoader(false);
      if(!userEmailError){
        setAcceptThankyou(true);
      }
    }, 1000);
  };
  const handleCountrySelection = (countryCode, countryName) => {
    setSelectedcountryCode(countryCode);
    setSelectedcountryName(countryName);
  };

  const handleClick = (type) => {
    setUserEmailError(false);
    setSelectedType(type);
    var elmntToView = document.getElementById("record_button_show");
    elmntToView.scrollIntoView();
  };


  // get data if uploaded already 
  // useEffect(()=>{
  //   if (Cookies.get("sessionID")) {
  //     const formData = new FormData();
  //     formData.append("sessionID", Cookies.get("sessionID"));
  //     axios
  //       .post(APIURL+"checkaudio.php", formData)
  //       .then((response) => {
  //         // console.log("response - - - - ", response)
  //         if(response?.data?.error!=true){
  //           setisalreadySubmit(true);
  //         // Handle the response data here
  //         }else{
  //           setisalreadySubmit(false)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         // Handle errors here
  //       });
  //   }
  // },[])

  return (
    <Grid className="insructions">
      <FileUploadDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onFilesUploaded={handleFilesUploaded}
      />
      {/* <AcceptTermsDialogBox
        open={acceptTC}
        onClose={handleDeclineTerms}
        handleStartRecording={handleStartRecording}
      /> */}
      <AcceptNewsLetter
        open={acceptTC}
        setisSessionID={setisSessionID}
        onClose={handleDeclineTerms}
        handleStartRecording={handleStartRecording}
      />
      <CaptchaDialogBox
        open={captchaVerify}
        onClose={handleCaptchaDialogClose}
        submitAudioFile={submitAudioFile}
      />
      <Thankyou open={acceptThankyou} onClose={handleDeclineThankyou} />
      {loader && (
        <UploadProgress
          requestCompleted={requestCompleted}
          handleLoaderComplete={handleLoaderComplete}
          open={loader}
          percentage={40}
        />
      )}
      <WelcomeCard />
      <Instructions />
      <Typography
        key={"record_your_voice"}
        variant="body1"
        color="secondary"
        className="record-voice"
        style={{ margin: "revert" }}
        id={"record_button_show"}
      >
        RECORD YOUR VOICE
      </Typography>
      <div className="audio-wrp-box">
        <Grid className="flex-box" style={{ marginBottom: "24px" }}>
          <span className="aud-time">00:0000</span>
          {!selectedFiles.length ? (
            <>
              {/* <Grid className="block1 mobile-icon1">
                <Tooltip title="Upload Audio Files" placement="bottom">
                  <IconButton
                    aria-label="Upload Audio Files"
                    size="sm"
                    variant="solid"
                    color="neutral"
                    className="new_round_buttons"
                    onClick={() => handleClick("Add")}
                    disabled={isalreadySubmit}
                  >
                    <AddIcon style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              </Grid> */}
              <Grid className="block1 mobile-icon2" style={{ margin: 0 }}>
                {!timer && isRecording ? (
                  <CircularProgress
                    variant="solid"
                    size="md"
                    determinate
                    value={progress}
                    style={{ width: "48px", height: "48px" }}
                    className={"joy_progress"}
                    onClick={handleStopRecordingOut}
                  >
                    <Tooltip title="Stop Recording" placement="bottom">
                      <IconButton
                        aria-label="Stop Recording"
                        size="sm"
                        variant="solid"
                        color="neutral"
                        className="new_round_buttons_stop"
                        disabled={audioChunks?.current?.length<4}
                      >
                        <StopIcon style={{ fontSize: "30px" }} />
                      </IconButton>
                    </Tooltip>
                  </CircularProgress>
                ) : (
                  <Tooltip
                    title={
                      resetRecording
                        ? "Restart Recording"
                        : "Make a New Recording"
                    }
                    placement="bottom"
                  >
                    <IconButton
                      aria-label={
                        resetRecording
                          ? "Restart Recording"
                          : "Make a New Recording"
                      }
                      size="md"
                      variant="solid"
                      color="neutral"
                      className={`voice new_round_buttons countdown-btn ${isalreadySubmit && "count_down_disabled"}`}
                      onClick={() => handleClick("Record")}
                      disabled={(timer>0 && showTimer) || isalreadySubmit}
                    >
                      {timer && showTimer ? (
                        timer
                      ) : resetRecording ? (
                        <ReplayIcon style={{ fontSize: "30px" }} />
                      ) : (
                        <MicIcon style={{ fontSize: "24px" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>{" "}
            </>
          ) : (
            <></>
            // <Grid className="block12" style={{ margin: 0 }}>
            //     <IconButton
            //       aria-label="Previous Recording"
            //       size="md"
            //       variant="solid"
            //       color="neutral"
            //       className="voice new_round_buttons"
            //       sx={{
            //         marginRight: "8px",
            //       }}
            //       onClick={handlePrevPage}
            //       disabled={currentPage === 0}
            //     >
            //   <Tooltip title="Previous Recording" placement="bottom">
            //       <SkipPreviousRoundedIcon style={{ fontSize: "45px" }} />
            //   </Tooltip>
            //     </IconButton>
            //     <IconButton
            //       aria-label="Next Recording"
            //       size="md"
            //       variant="solid"
            //       color="neutral"
            //       className="voice new_round_buttons"
            //       onClick={handleNextPage}
            //       disabled={(currentPage + 1) * 1 >= selectedFiles.length}
            //     >
            //   <Tooltip title="Next Recording" placement="bottom">
            //       <SkipNextRoundedIcon style={{ fontSize: "45px" }} />
            //   </Tooltip>
            //     </IconButton>
            // </Grid>
          )}
          {currentFiles && currentFiles.length ? (
            <>
              {currentFiles.map((file, index) => {
                return (
                  <WaveSurferPlayer
                    container={"#waveform"}
                    key={index + file.name}
                    height={48}
                    cursorColor={"navy"}
                    barWidth={3}
                    barRadius={3}
                    responsive={true}
                    cursorWidth={1}
                    backend={"WebAudio"}
                    waveColor={"gray"}
                    progressColor={"white"}
                    url={URL.createObjectURL(file)}
                    plugins={[]}
                    isRecording={isRecording}
                    isuploading={true}
                    interact={false}
                    resetRecording={false}
                    checkMarkerAdded={checkMarkerAdded}
                    type={"files"}
                  />
                );
              })}
              {/* <span className="file-numbers">
                {currentPage + 1} /{selectedFiles.length}
              </span> */}
            </>
          ) : (
            <WaveSurferPlayer
              container={"#waveform"}
              height={48}
              cursorColor={"white"}
              barWidth={3}
              barRadius={3}
              responsive={true}
              cursorWidth={1}
              backend={"WebAudio"}
              waveColor={"#46a6d8"}
              progressColor={"#FFF"}
              url={audioUrl}
              plugins={[]}
              isRecording={isRecording}
              resetRecording={!resetRecording}
              interact={false}
              checkMarkerAdded={checkMarkerAdded}
              normalize={true}
              blob={blob}
              trimData={trimValue}
              showTimer={showTimer}
              setTrimValue={(trim) => {
                trimValue.current = trim;
              }}
            />
          )}
        </Grid>
        <Grid container spacing={1}>
          <Grid
          
            lg={3}
            md={3}
            sm={3}
            xs={12}
            style={{ textAlign: "center" }}
            className={"email_div1"}
          >
            {/* <input
              onChange={(e) => handleEmailValidation(e.target.value)}
              value={userEmail}
              title="Enter your email address"
              className="input-width rad"
              placeholder="Enter your email address"
              type="email"
              required
            ></input>
            
            {!isValidEmail && userEmail != "" && (
              <small className="emailerror">Please enter a valid email</small>
            )} */}
            {userEmailError && (
              <small className="emailerror">{errorMessage}</small>
            )}
          </Grid>
          <Grid lg={4} md={4} sm={5} xs={12} className={"select_div"}>
            {
              <Selectdropdown
                handleCountrySelection={handleCountrySelection}
                defaultCountryCode="229"
                selectedcountryCode={selectedcountryCode}
                isalreadySubmit={isalreadySubmit}
              />
            }
          </Grid>
          <Grid lg={3} md={3} sm={3} xs={12} className={"submit_div"}>
              <Button
                onClick={handleCaptchaDialogOpen}
                type="submit"
                className="btn-submit rad redis-btn"
                color="secondary"
                variant="contained"
                disabled={
                  // !isValidEmail ||
                  isRecording ||
                  (!audioUrl && !selectedFiles.length)
                  || isalreadySubmit
                  || !selectedcountryName
                }
              >
                <Tooltip title="Submit Your Recording" placement="bottom">
                  <span>SUBMIT</span>
                </Tooltip>
              </Button>
          </Grid>
        </Grid>
      </div>
      <LeaderBoard />
      <ButtonLink />
    </Grid>
  );
};
export default AudioRecorder;
