import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CountryNames from "../Assets/CountryNames/CountryNames";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from '@mui/material/Tooltip';
import solid_yellow from "../Assets/img/solid_yellow.png";

const currencies = CountryNames;

const Selectdropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState({
    "flags": {
        "png": `${solid_yellow}`,
        "alt": "Select Country"
    },
    "name": {
        "common": "",
    },
    "cca3": "Select Country",
    "flag": "🇬🇧"
});
  const [query, setQuery] = useState("");

  // handle onChange event of the dropdown
  const handleCountryChange = (e) => {
    const { cca3 } = e.target.value;
    let countryName = e?.target?.value?.name?.common;
    props.handleCountrySelection(cca3, countryName);
    setSelectedOption(e.target.value);
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleSearchCountryName = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setQuery(e.target.value);
  };
  const filteredOptions = CountryNames.sort(function(a, b) {
    var textA = a?.name?.common?.toUpperCase();
    var textB = b?.name?.common?.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  // useEffect(()=>{
  //   setSelectedOption(filteredOptions[props.defaultCountryCode])
  // }, [filteredOptions])
 
  function mySearchFunction() {
    // Declare variables
    var input, filter, li, a, i, txtValue;
    input = document.getElementById('input-with-icon-textfield');
    filter = input.value.toUpperCase();
    li = document.getElementsByClassName('countries-item');
    for (i = 0; i < li.length; i++) {
      a = li[i];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().startsWith(filter)) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  return (
    <Grid className="Selectdropdown">
      <Select
        sx={{
          borderRadius: "5rem",
          height: "5rem",
          // border: "1px solid #9a50ff",
          backgroundColor: "transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9a50ff",
          },
          width: "100%",
          "& .MuiSelect-iconOutlined": {
            color: "#FFFFFF",
            fontSize: "35px",
          },
        }}
        disabled={props.isalreadySubmit}
        className="select-dropdown"
        defaultValue={CountryNames["225"]}
        value={selectedOption}
        displayEmpty
        onChange={handleCountryChange}
        renderValue={(value) => {
          return (
            <Tooltip title="Select Your Country/Region of Origin" placement="bottom">
                <Box
                  sx={{ display: "flex", gap: 1, fontSize: "large", color: "#FFF" }}
                >
                  <Avatar
                    alt={value?.flags?.alt}
                    src={value?.flags?.png}
                    style={{ marginLeft: "-10px" }}
                  />
                  <span className="eleps" style={{ padding: "12px 0" }}>
                    {value?.cca3}
                  </span>
                </Box>
          </Tooltip>
          );
        }}
      >
        <MenuItem
          dense
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={handleSearchCountryName}
          className="search-box"
          disabled
        >
          <TextField
            id="input-with-icon-textfield"
            className="inbox-search"
            placeholder="Search"
            value={query}
            onChange={mySearchFunction}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              ),
            }}
          />
        </MenuItem>
        {filteredOptions.map((option) => {
          const { name } = option;
          return (
            <MenuItem
              key={option?.cca3}
              className="countries-item"
              value={option}
              style={{ fontSize: "1.5rem" }}
            >
              <img
                alt={option?.flags?.alt}
                src={option?.flags?.png}
                style={{ marginRight: "10px", maxWidth: "40px" }}
              />
              {/* <Tooltip key={option?.cca3} title={name?.common} placement="top"> */}
              <span>{name?.common}</span>
              {/* </Tooltip> */}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
};

export default Selectdropdown;
