import React, { useState, useEffect, useRef, useCallback } from "react";
import { IconButton, Grid } from "@mui/joy";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWavesurfer from "../Hooks/WavesurferHooks";
import Tooltip from "@mui/material/Tooltip";

const WaveSurferPlayer = (props) => {
  const containerRef = useRef();
  const activeRegion = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:0000");
  const [runningTime, setRunningTime] = useState(0);
  const updatedProps = { ...props, setCurrentTime };
  const wavesurfer = useWavesurfer(containerRef, updatedProps, activeRegion);
  const { isRecording, resetRecording, showTimer, isuploading } = props;
  let loop = true;
  // On play button click
  const onPlayClick = useCallback(() => {
    scrollToCenter();
    if (!wavesurfer.current) return;
    if (activeRegion.current) {
      if (wavesurfer.current.isPlaying()) {
        setIsPlaying(false);
        wavesurfer.current.pause();
      } else {
        setIsPlaying(true);
        if (Math.round(runningTime) >= Math.round(activeRegion.current.end) || Math.round(activeRegion.current.start) !== 0 ) {
          // wavesurfer.current.play();
          activeRegion.current.play();
        } else {
          wavesurfer.current.play();
        }
      }
    } else if (wavesurfer.current.isPlaying()) {
      setIsPlaying(false);
      wavesurfer.current.pause();
    } else {
      setIsPlaying(true);
      wavesurfer.current.play();
    }
  }, [wavesurfer, runningTime, activeRegion]);

  useEffect(() => {
    if (!wavesurfer.current && !isRecording) return;
    if (isRecording) {
      setCurrentTime("00:0000");
    }
    const subscriptions = [
      wavesurfer.current.on("play", () => {
        setIsPlaying(true);
      }),
      wavesurfer.current.on("pause", () => {
        setIsPlaying(false);
      }),
      wavesurfer.current.on("timeupdate", (time) => {
        setRunningTime(time);
        isPlaying
          ? setCurrentTime(parseFloat(time.toFixed(4)))
          : setCurrentTime(`${Math.round(time)}:0000`);
      }),
      wavesurfer.current.on("decode", () => {
        setCurrentTime(`${Math.round(wavesurfer.current.getDuration())}:0000`);
      }),
    ];
    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [isRecording, loop, isPlaying]);

  useEffect(() => {
    setIsPlaying(false);
    setCurrentTime("00:0000");
  }, [isRecording, resetRecording, showTimer]);

  const scrollToCenter = () => {
    var elmntToView2 = document.getElementById("record_button_show");
    elmntToView2.scrollIntoView();
  };

  return (
    <>
      <Grid className="block1">
        <IconButton
          onClick={onPlayClick}
          aria-label={isPlaying ? "Pause Your Recording" : "Play Your Recording"}
          size="md"
          variant="solid"
          color="neutral"
          className="playM new_round_buttons"
          sx={{
            fontSize: "24px !important",
          }}
          disabled={resetRecording || isRecording || showTimer}
        >
          <Tooltip
            title={isPlaying ? "Pause Your Recording" : "Play Your Recording"}
            placement="bottom"
          >
            <FontAwesomeIcon
              style={{ marginLeft: isPlaying ? "" : "2px" }}
              icon={isPlaying ? faPause : faPlay}
            />
          </Tooltip>
        </IconButton>
      </Grid>
      <Tooltip title={"Your Audio File"} placement="bottom">
        <Grid id="waveform" className="block2  seek-bar" ref={containerRef} style={{width: isuploading && "calc(100% - 65px)"}}/>
      </Tooltip>
      <span className="total-count-down">{currentTime}</span>
    </>
  );
};
export default WaveSurferPlayer;
