import React from "react";
import { Grid, Typography } from "@mui/joy";
import VideoPlayer from "./VideoPlayer"


const Insructions = () => {
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;

    return (
        <>
            <h4>INSTRUCTIONS</h4>
            <Typography component="div" style={{ fontSize: "22px", color: "#FFF" }}>
                <ul className="textcontentcentralized">
                    <li>Record your "Ahhhhh" by pressing the microphone icon below</li>
                    <li>No words or phrases please</li>
                    <li>You can listen to your vocal and re-record if necessary</li>
                    <li>Click on the tickbox if you'd like to be credited in the track's digital booklet</li>
                    {/* <li>You can trim your recording by moving the red and green handles</li> */}
                    <li>To participate, you must be the older of either (i) the age of majority in your jurisdiction of residence or (ii) the age of 18</li>
                    {isSafari && <li>NB: the recording function on this website works best with Chrome and Edge browsers</li>}
                </ul>
            </Typography>
            <VideoPlayer />
            <Grid className="separator"></Grid>
        </>
    );
};

export default Insructions;