import React from "react";
// import { Player, BigPlayButton, ControlBar, ForwardControl } from "video-react";
import PosterImage from "../Assets/img/poster.png";
// import "../../node_modules/video-react/dist/video-react.css"; // import css
import ReactPlayer from 'react-player'

const VideoPlayer = () => {
  return (
    <ReactPlayer
      className='react-player-youtube optanon-category-C0003'
      // url="https://www.youtube-nocookie.com/watch?v=s7LdYEO2Vfk"
      url="https://youtu.be/zthHm0Z5A6U?"
      light={<img src={PosterImage} alt='Welcome Message' className="image-thumbnail" style={{maxHeight: "412px", borderRadius: "20px", border: "1px solid"}} />}
      playing
      controls
      width={733}
      height={412}
    />
  );
};

export default VideoPlayer;
