import * as React from 'react';
import Box from '@mui/joy/Box';
import Grid from "@mui/material/Grid";
import {PRIVACYPOLICY, TANDC} from "../../constant";
export default function ButtonLink() {
  return (
    <Box sx={{textAlign: "center" , width: "100%"}}>
      <Grid className="separator" style={{marginBottom: "40px"}}></Grid>
      <p className='coldplay_footer_links'>
        <a href="https://www.coldplay.com/" target='_blank' className='coldplay_footer_redirect_link'>©COLDPLAY 2023</a> | 
        <a href={PRIVACYPOLICY} target='_blank' className='coldplay_footer_redirect_link'>Privacy Policy</a> | 
        <a href={TANDC} target='_blank' className='coldplay_footer_redirect_link'>Terms of Use</a> | 
        <a href="https://www.wminewmedia.com/cookies-policy/?ot=5aaaddf4-be7f-48cf-bd94-9ac886f35916&url=www.coldplay.com" target='_blank' className='coldplay_footer_redirect_link'>Cookies Policy</a> | 
        <a href="#" className='coldplay_footer_redirect_link ot-sdk-show-settings'>Cookies Settings</a>
      </p>
      <p className='coldplay_redirect'><a href="https://www.coldplay.com/" data-track="enter-site" target='_blank' className='coldplay_redirect_link'>Continue to coldplay.com</a></p>
    </Box>
  );
}